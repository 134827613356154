import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import DownloadIosImage from "../components/DownloadIosImage"
import DownloadAndroidImage from "../components/DownloadAndroidImage"
import TsIosScreenshotsImage from "../components/TsIosScreenshotsImage"

const DownloadPage = () => (
  <Layout pageInfo={{ pageName: "download" }}>
    <Seo title="TrackSecure App" keywords={[ `Rennstrecke`, `Trackday`, `fahren`, `safety`, `Auto`, `Tracktool`, `Fahrsicherheit`, `Sicherheit`, `Mobilität`, `TrackSecure`, `App` , `iOS`, `Android`, `Download` ]} />
    <h1 className="heading-4">TrackSecure App</h1>
    <br/>
    <TsIosScreenshotsImage/>
    <br/>
    <p className="content-text">
    Du willst TrackSecure als Fahrer nutzen? Die TrackSecure App ist zum Download in den gängigen App Stores verfügbar.
    </p>
    <p className="content-text">
    In weniger als 5min ab auf die Strecke. Nach dem Download direkt in der App einen Account anlegen und sofort TrackSecure nutzen.
    Da uns wichtig ist, dass verantwortungsvoll mit Meldungen umgegangen wird, benötigen wir deine Telefonnummer damit dein Account erstellt werden kann.
    </p>
    <p className="content-text">
    Du möchtest uns Feedback zur App geben? Kontaktiere uns via <a target="_blank" rel="noopener noreferrer" href="https://tracksecure.atlassian.net/servicedesk/customer/portal/4">Servicedesk-Formular</a> oder <a href="mailto:support-ma@tracksecure.atlassian.net">eMail</a>.
    </p>
    <p className="content-text">
    Hier könnt Ihr die App auf eurem Smartphone installieren:
    </p>
    <ul className="list-unstyled">
      <li><a href="https://apps.apple.com/us/app/tracksecure/id1496688443?l=de&ls=1" aria-label="download ios app"><DownloadIosImage/></a></li>
      <li><a href="https://play.google.com/store/apps/details?id=com.tracksecure.driver" aria-label="download android app"><DownloadAndroidImage/></a></li>
    </ul>
  </Layout>
)

export default DownloadPage
