import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

const DownloadAndroidImage = (imgName) => {
  const data = useStaticQuery(graphql`{
  placeholderImage: file(relativePath: {eq: "googlePlay_button.png"}) {
    childImageSharp {
      gatsbyImageData(width: 148, height: 44, layout: FIXED)
    }
  }
}`)

  return <GatsbyImage image={data.placeholderImage.childImageSharp.gatsbyImageData} />;
}

export default DownloadAndroidImage
